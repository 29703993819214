body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (min-width: 768px) {
  .custom-img {
    margin-top: -300px;
  }
}

.profile-img {
  display: inline-block; /* Imposta il display su inline-block */
}

.profile-img.float-animation {
  margin-left: -80%;
  animation: float 3s ease-in-out infinite; /* Imposta la durata, l'effetto, e il numero di ripetizioni desiderato */
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0); /* Muovi solo lungo l'asse Y */
  }
  50% {
    transform: translateY(12px); /* Muovi verso l'alto di 10px */
  }
}

@media (max-width: 572px) {
  .profile-img.float-animation{
   margin-left: -40%;
   width: 120%;
   height: 120% !important; 
  }
  .profile-img.shape{
    margin-left: 40px; 
    width: 110%;
    height: 110% !important;
   }
}

.animated-card {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.animated-card.show {
  opacity: 1;
  transform: translateY(0);
}

.sx-layout{
  text-align: left;
}




